import Vue from 'vue'
import axios from 'axios'

// Vue.prototype.$baseURL = 'https://test-admin.gram.tj/api/'
Vue.prototype.$baseURL = 'https://api-3taxi.gram.tj/api/'

const axiosIns = axios.create({
	baseURL: Vue.prototype.$baseURL,
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(response => {
	return response
}, (error) => {		
	const { response } = error;
	if (response?.status === 401) {
		localStorage.removeItem('accessToken')
	}
	throw error;
});

export default axiosIns
